import { useEffect } from "react";

function Jumbotron() {
  useEffect(() => {
    // ScrollReveal().reveal(".sr-element", {
    //     distance: "10%",
    //     origin: "left",
    //     interval: 50
    // });
  }, []);

  const onChatActionClick = event => {
    if (window?.fbchatLoaded) {
      event.preventDefault();
      window.FB.CustomerChat.show();
    }
  };

  return (
    <>
      <p className="sr-element">მოგესალმებით!</p>
      <p className="sr-element my-2.5 text-5xl md:my-4">
        მე ვარ{" "}
        <span className="text-primary-200 dark:text-primary-100">ნიკა</span>
      </p>
      <p className="sr-element">
        Front-End{" "}
        <span className="text-primary-200 dark:text-primary-100">
          დეველოპერი
        </span>
      </p>
      <p className="sr-element">უკვე 5 წელია ვქმნი ვებ საიტებს და დიზაინებს.</p>
      <div className="my-2.5 md:my-3">
        <p className="sr-element">
          <span className="text-primary-200 dark:text-primary-100">
            ელ-ფოსტა:
          </span>{" "}
          <a
            className="hover:text-primary-200 dark:hover:text-primary-100 transition-colors"
            href="mailto:info@nshamanauri.ge"
          >
            info@nshamanauri.ge
          </a>
        </p>
        {/* <div className="space-x-6 sm:mx-auto md:mx-0 mt-1">
          <a
            className="inline-block rounded-full text-sm transition-colors hover:text-primary-200"
            href="https://www.facebook.com/webdesigner.ge.official"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="1em"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                className="fill-current"
                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
              />
            </svg>
          </a>
          <a
            className="inline-block rounded-full text-sm transition-colors hover:text-primary-200"
            href="https://github.com/nikshama"
            target="_blank"
            rel="noreferrer"
          >
            <svg width="1em" height="1em" viewBox="0 0 496 512">
              <path
                className="fill-current"
                d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
              />
            </svg>
          </a>
          <a
            className="inline-block rounded-full text-sm transition-colors hover:text-primary-200"
            href="mailto:info@webdesigner.ge"
          >
            <svg
              width="1em"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                className="fill-current"
                d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM32 128V167.9L227.6 311.3C244.5 323.7 267.5 323.7 284.4 311.3L480 167.9V128C480 110.3 465.7 96 448 96H63.1C46.33 96 31.1 110.3 31.1 128H32zM32 207.6V384C32 401.7 46.33 416 64 416H448C465.7 416 480 401.7 480 384V207.6L303.3 337.1C275.1 357.8 236.9 357.8 208.7 337.1L32 207.6z"
              />
            </svg>
          </a>
        </div> */}
      </div>
      <div className="my-6">
        <a
          onClick={onChatActionClick}
          href="https://m.me/307111816336099"
          target="_blank"
          rel="noreferrer"
          className="inline-block rounded-full border-2 border-primary-200 py-3 px-12 font-bold text-primary-200 transition-colors hover:bg-primary-200 hover:text-white"
        >
          მომწერე ახლავე
        </a>
      </div>
    </>
  );
}

export default Jumbotron;
