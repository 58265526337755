import { useEffect, useRef } from "react";

import Jumbotron from "./Jumbotron";
import Navbar from "./Navbar";

import reveal from "../../globals/reveal";

import jumbotronImage from "../../assets/images/sections/jumbotron.png";

function Header() {
  const imageRef = useRef(null);

  useEffect(() => {
    reveal(imageRef.current, {
      distance: "10%",
      origin: "right"
    });
  }, []);

  return (
    <div className="relative flex flex-col overflow-hidden min-h-screen">
      <Navbar />
      <div className="container items-center py-6 xl:py-10">
        <div className="-mx-3 flex flex-col flex-wrap justify-between overflow-hidden md:flex-row md:items-center">
          <div className="order-2 mt-5 px-3 text-lg md:order-1 md:mt-0 md:w-6/12 xl:w-4/12 3xl:w-3/12">
            <Jumbotron />
          </div>
          <div
            ref={imageRef}
            className="order-1 w-full px-3 md:order-2 md:w-6/12 xl:w-6/12 3xl:w-7/12"
          >
            <img src={jumbotronImage} alt="jumbotron" />
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 -z-10">
        <svg
          className="h-20 w-24"
          viewBox="0 0 97 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-[#ecebf3] dark:fill-[#1a1921]"
            d="M0 79.341C63.013 85.565 96.076 0.00100708 96.076 0.00100708H0V79.341Z"
          />
        </svg>
      </div>
      <div className="absolute left-0 top-3/4 -z-10 lg:top-1/2">
        <svg
          className="h-32 w-[68px]"
          viewBox="0 0 68 130"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-[#ecebf3] dark:fill-[#1a1921]"
            d="M34.9641 9.86799C89.6981 49.238 59.0311 76.683 59.0311 76.683C53.2236 79.1071 47.9723 82.6917 43.5991 87.217C40.5821 90.465 37.999 96.066 36.999 105.397C33.833 134.924 0.132996 128.845 0.132996 128.845L0.132996 14.898C0.132996 14.898 -2.80703 -7.50202 18.905 2.62998C40.617 12.762 34.9641 9.86799 34.9641 9.86799Z"
          />
        </svg>
      </div>
      <div className="absolute bottom-[20%] right-0 -z-10 md:bottom-[5%]">
        <svg
          className="h-[138px] w-[88px]"
          viewBox="0 0 88 138"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="fill-[#ecebf3] dark:fill-[#1a1921]"
            d="M87.345 0.0247666C83.026 -0.616233 21.6 25.8797 21.6 25.8797C0.911989 30.8057 23.57 59.3798 21.6 83.0198C19.63 106.66 -14.112 107.655 6.82301 123.171C27.758 138.687 86.36 138.189 87.345 136.219C88.33 134.249 87.345 112.334 87.345 0.0247666Z"
          />
        </svg>
      </div>
    </div>
  );
}

export default Header;
