function Navbar() {
  const onChatActionClick = event => {
    if (window?.fbchatLoaded) {
      event.preventDefault();
      window.FB.CustomerChat.show();
    }
  };

  return (
    <nav
      id="section-navbar"
      className="container flex items-center justify-center sm:justify-between py-4"
    >
      <a href="/" onClick={() => false}>
        <svg
          width="46"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 35.34 23.04"
        >
          <path
            className="fill-primary-200 dark:fill-primary-100"
            d="M23.07-22.44,17.97,0H13.83L10.35-14.88,6.93,0H4.086L8.7-20.43a6.412,6.412,0,0,0-3.03,2.385A7.246,7.246,0,0,0,4.59-13.92a7.777,7.777,0,0,0,.045.93,3.127,3.127,0,0,0,.12.585q.075.225.135.36t.12.255a3.744,3.744,0,0,1-2.364-.645A2.734,2.734,0,0,1,1.83-14.7a6.831,6.831,0,0,1,.608-2.745,7.875,7.875,0,0,1,1.77-2.52A9,9,0,0,1,7.08-21.8a10.158,10.158,0,0,1,3.9-.7,8.826,8.826,0,0,1,.915.06,3.7,3.7,0,0,1,1.185.36L16.59-6.15l3.57-16.29Zm14.1,3.54a3.456,3.456,0,0,1-.66,2.175,2.151,2.151,0,0,1-1.8.855,1.736,1.736,0,0,1-1.38-.63,2.622,2.622,0,0,0,.975-1.1,3.238,3.238,0,0,0,.375-1.485,2.147,2.147,0,0,0-.48-1.38,2.124,2.124,0,0,0-1.74-.6,2.892,2.892,0,0,0-2.01.675,2.587,2.587,0,0,0-.75,2.025,3.689,3.689,0,0,0,.5,1.935,7.825,7.825,0,0,0,1.245,1.56q.75.735,1.635,1.47a12.148,12.148,0,0,1,1.635,1.65A8.473,8.473,0,0,1,35.955-9.66a7.136,7.136,0,0,1,.495,2.79,7.2,7.2,0,0,1-.7,3.285,6.752,6.752,0,0,1-1.875,2.31A8,8,0,0,1,31.17.09a11.106,11.106,0,0,1-3.15.45A10.714,10.714,0,0,1,25.575.27,5.725,5.725,0,0,1,23.55-.615,4.464,4.464,0,0,1,22.17-2.19a4.934,4.934,0,0,1-.51-2.34,5.467,5.467,0,0,1,.39-2.13,4.625,4.625,0,0,1,1.02-1.545,4.283,4.283,0,0,1,1.425-.93A4.252,4.252,0,0,1,26.07-9.45a4.077,4.077,0,0,1,.825.09,2.578,2.578,0,0,1,.81.315,2.093,2.093,0,0,1,.645.615,2.291,2.291,0,0,1,.36.96,4.955,4.955,0,0,0-1.26.165,3.682,3.682,0,0,0-1.14.51,2.791,2.791,0,0,0-.84.9,2.566,2.566,0,0,0-.33,1.335A2.636,2.636,0,0,0,25.9-2.64a2.951,2.951,0,0,0,2.2.78A3.289,3.289,0,0,0,30.6-2.865a4.03,4.03,0,0,0,.96-2.895,5.774,5.774,0,0,0-.45-2.325,8.846,8.846,0,0,0-1.14-1.92,21.635,21.635,0,0,0-1.485-1.71q-.8-.825-1.485-1.65a8.681,8.681,0,0,1-1.14-1.74,4.526,4.526,0,0,1-.45-2.025,4.438,4.438,0,0,1,.54-2.16,5.289,5.289,0,0,1,1.47-1.695,7.246,7.246,0,0,1,2.205-1.11A8.917,8.917,0,0,1,32.34-22.5a10.843,10.843,0,0,1,1.35.105,5.451,5.451,0,0,1,1.59.465,3.551,3.551,0,0,1,1.335,1.08A3.116,3.116,0,0,1,37.17-18.9Z"
            transform="translate(-1.83 22.5)"
          />
        </svg>
      </a>
      <ul className="hidden items-center lg:static sm:flex lg:w-auto lg:flex-row lg:space-x-6">
        <li>
          <a
            onClick={onChatActionClick}
            href="https://m.me/307111816336099"
            target="_blank"
            rel="noreferrer"
            className="inline-block rounded-full bg-gradient-to-r from-primary-200 to-purple-600 px-6 py-2.5 font-bold text-white
                      shadow-lg shadow-purple-600/25 transition hover:shadow-xl hover:shadow-purple-600/25 active:from-violet-700
                      active:to-purple-700 lg:px-8 lg:py-3.5"
          >
            დამიკავშირდი
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
